const moment = require('moment');
const { DATETIME_FORMAT } = require('@/utils/constants');
const DATE_FORMAT = 'DD/MM/YYYY';
const YEAR_MONTH_DAY_FORMAT = 'YYYY-MM-DD';
const DATETIME_DB_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const MOMENT_METHODS = Object.freeze({
  ADD: 'add',
  SUBTRACT: 'subtract',
});

const DP_CONFIG = {
  date: {
    format: 'DD/MM/YYYY',
    useCurrent: false,
    showClear: true,
    showClose: true,
    minDate: false,
    maxDate: false,
    locale: 'vi',
    sideBySide: true,
    allowInputToggle: true,
  },
  time: {
    format: 'LT',
    useCurrent: true,
    sideBySide: true,
    locale: 'vi',
    allowInputToggle: true,
  },
};

const DP_CONFIG_DATETIME = {
  date: {
    format: DATETIME_FORMAT,
    useCurrent: false,
    showClear: true,
    showClose: true,
    minDate: false,
    maxDate: false,
    sideBySide: true,
    locale: 'vi',
  },
};

const formatDate = (date, format) => {
  return moment(date).format(format || 'YYYY-MM-DD');
};

const formatSpecificDate = (date, currentFormat, newFormat) => {
  return date ? moment(date, currentFormat).format(newFormat) : null;
};

const formatToISODate = (date, currentFormat, toFormat = null) => {
  return moment(date, currentFormat).format(toFormat);
};

const getSubtractDate = (subtract = 0, format = DATE_FORMAT) => {
  return moment()
    .subtract(subtract, 'days')
    .format(format);
};

const getAddDate = (add = 0, format = DATE_FORMAT) => {
  return moment()
    .add(add, 'days')
    .format(format);
};

/**
 * @param {String} startDate
 * @param {String} endDate
 * @param {String} format
 * @returns {Number}
 */
const getRemainingDays = (startDate, endDate, format = DATETIME_FORMAT) => {
  return moment(endDate, format).diff(moment(startDate, format), 'days');
};

/**
 * @param {Boolean} enableCustomCurrentDate
 * @param {String} currentDate
 * @param {String} format
 * @returns {*|moment.Moment}
 */
const getMoment = (
  enableCustomCurrentDate,
  currentDate,
  format = DATE_FORMAT,
) => {
  return enableCustomCurrentDate && currentDate
    ? moment(currentDate, format)
    : moment();
};

/**
 * Get current date by format and enable custom current date for testing purposes
 *
 * @param {String} format
 * @param {Boolean} [enableCustomCurrentDate]
 * @param {String} [currentDate]
 * @returns {String}
 */
const getCurrentDateByFormat = ({
  format = DATE_FORMAT,
  enableCustomCurrentDate = false,
  currentDate,
}) => {
  _validateCustomCurrentDate(enableCustomCurrentDate, currentDate);
  return moment(getMoment(enableCustomCurrentDate, currentDate)).format(format);
};

/**
 * @param {String} date
 * @param {String} format
 * @returns {boolean}
 */
const _isValidDate = (date, format = DATE_FORMAT) => {
  return moment(date, format).isValid();
};

/**
 * @param {Boolean} enableCustomCurrentDate
 * @param {String} currentDate
 * @private
 */
const _validateCustomCurrentDate = (enableCustomCurrentDate, currentDate) => {
  if (enableCustomCurrentDate) {
    if (!currentDate) {
      throw new Error('Current date is required');
    }
    if (!_isValidDate(currentDate)) {
      throw new Error('Current date is invalid');
    }
  }
};

/**
 * Dynamically get date by amount and unit calculation default to addition
 *
 * @param {Number} amount
 * @param {String} unit - days, months, years
 * @param {String} currentDate - Date string originates neither from moment nor Date Object
 * @param {Boolean} enableCustomCurrentDate
 * @param {String} format
 * @param {String} METHOD
 * @returns {String}
 */
const getCalculatedTimeByUnit = ({
  amount,
  unit = 'days',
  currentDate,
  enableCustomCurrentDate = false,
  format = DATE_FORMAT,
  METHOD = MOMENT_METHODS.ADD,
}) => {
  _validateCustomCurrentDate(enableCustomCurrentDate, currentDate);

  return moment(getMoment(enableCustomCurrentDate, currentDate), format)
    [METHOD](amount, unit)
    .format(format);
};

const getMonth = (date) => {
  if (!date) {
    console.error('Received no date info');
  }
  _isValidDate(date);
  return moment(date, DATE_FORMAT).month();
}

const getStartOfMonth = (date, format = DATE_FORMAT) => {
  if (!date) {
    console.log('Received no date info');
  }
  _isValidDate(date);
  return moment(date, format).startOf('month').format(DATE_FORMAT);
}

const getEndOfMonth = (date, format = DATE_FORMAT) => {
  if (!date) {
    console.log('Received no date info');
  }
  _isValidDate(date);
  return moment(date, format).endOf('month').format(DATE_FORMAT);
}


const getStartOfDay = (date, format = DATE_FORMAT) => {
  const isValiDate = moment(date, format).isValid();
  return isValiDate
    ? moment(date, format)
        .startOf('day')
        .format(DATETIME_DB_FORMAT)
    : null;
};

const getEndOfDay = (date, format = DATE_FORMAT) => {
  const isValiDate = moment(date, format).isValid();
  return isValiDate
    ? moment(date, format)
        .endOf('day')
        .format(DATETIME_DB_FORMAT)
    : null;
};

module.exports = {
  formatDate,
  getSubtractDate,
  getAddDate,
  formatToISODate,
  getCurrentDateByFormat,
  getAddTimeByUnit: getCalculatedTimeByUnit,
  DP_CONFIG,
  getRemainingDays,
  DP_CONFIG_DATETIME,
  YEAR_MONTH_DAY_FORMAT,
  formatSpecificDate,
  DATETIME_DB_FORMAT,
  getStartOfDay,
  getEndOfDay,
  DATE_FORMAT,
  getMonth,
  getStartOfMonth,
  getEndOfMonth,
};
